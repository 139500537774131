import React from 'react';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import ProjectHeader from '../components/ProjectHeader';
import Grid from '../components/Grid';
import AssetWithCaption from '../components/AssetWithCaption';
import Text from '../components/Text';

const Homepage = () => (
  <Layout>
    <TitleAndTheme
      title="Edwin Morris, Genius homepage"
      themeClass="theme--homepage"
    />

    <ProjectHeader
      name="Homepage"
      subhead="Designing Genius’ first impression"
    />

    <Text>
      <p>
        In 2016, the homepage for Genius was just a list of trending songs and a
        paragraph about the company — video and editorial had been growing for a
        year but weren’t represented.
      </p>
      <p>
        The goal of redesigning the homepage was to show visitors everything we
        offered without having to explain it in a paragraph, and to allow
        interested visitors to dig deeper into any of those categories to learn
        more. I led the initial design explorations for desktop.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="left_half"
        image="homepage/song_chart_closeup"
        width="1000"
        height="1000"
      >
        A list of top songs, trending up and down.
      </AssetWithCaption>
      <AssetWithCaption
        position="right_half"
        image="homepage/song_item_closeup"
        width="1000"
        height="1000"
      >
        Heavy image treatment creates consistency between each cover.
      </AssetWithCaption>
      <AssetWithCaption
        position="left_half"
        image="homepage/community_closeup"
        width="2000"
        height="1000"
      />
      <AssetWithCaption
        position="right_half"
        image="homepage/artist_chart_closeup"
        width="2000"
        height="1000"
      />
    </Grid>

    <Text>
      <p>
        In addition to adding more types of content, we wanted to double down on
        the trending songs list from the old homepage, and turn it into a real
        Genius Chart.
      </p>
      <p>
        Top songs are ranked by the number of visitors reading the lyrics at the
        moment you loaded the website, which can range from a couple hundred to
        a couple thousand.
      </p>
      <p>
        This design shines a bright light on the top four items, and lets the
        remaining 10, which change more often than the top 4, fight it out
        below.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="left_half"
        image="homepage/song_chart"
        width="1400"
        height="1120"
      />
      <AssetWithCaption
        position="right_half"
        image="homepage/artist_chart"
        width="1400"
        height="1120"
      />
      <AssetWithCaption
        position="full"
        image="homepage/page_with_songs"
        width="2000"
        height="4500"
      />
    </Grid>

    <Text noBottomMargin>
      <p>
        Before this project, the homepage never got a lot of attention, since
        most traffic goes to the song page. The more Genius becomes a media
        company, the more important the homepage will become.
      </p>
      <p>
        Now it’s not only clear from the page what Genius does, and what kind of
        content you can find on the site, but it’s also a destination for
        seasoned users, who can dig through a ton of different types of content
        from one place.
      </p>
    </Text>
  </Layout>
);

export default Homepage;
